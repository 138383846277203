






























































































































































































































































































































.framePage-body{
  background: transparent;
  position: relative;
}
.centerBox{
  min-width: 665px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
}
.centerTitle{
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}
.centerDesc{
  text-align: center;
  font-size: 12px;
  color: #999;
  padding-bottom: 14px;
}
.formTitle{
  margin-top: 16px;
  margin-bottom: 30px;
}
/deep/.el-upload{
  border: 0;
  margin: 0 auto;
  width: 184px;
  height: 118px;
}
/deep/.el-form-item{
  margin-left: 30px;
  margin-right: 60px;
}

/deep/.uploadFormItem{
  float: left;
  width: 50%;
  margin: 0 0 10px;
  .el-form-item__error{
    padding-top: 8px;
    margin-left: 80px;
  }
}
.btn-box{
  text-align: center;
  .el-button{
    margin-top: 8px;
    width: 246px;
    padding-top: 0;
    padding-bottom: 0;
    height: 34px;
    line-height: 34px;
  }
}
